import { createEvent, createStore, sample } from 'effector';

import { CityIndex200CitiesItem } from '@/shared/api/generated/api.generated';

export const $cityInForm = createStore<null | CityIndex200CitiesItem>(null);
export const $dateInForm = createStore(new Date().toLocaleDateString('pt-br').split('/').reverse().join('-'));
export const $startTimeIntervalInForm = createStore(`${new Date().getHours() + 1}:00`);
export const $endTimeIntervalInForm = createStore<string>('00:00');
export const $timeInForm = createStore<string>('00:00');
export const $stepHour = createStore<number>(1);

export const changeCityNameInForm = createEvent<CityIndex200CitiesItem>();
export const changeDateInForm = createEvent<string>();
export const changeStartTimeIntervalInForm = createEvent<string>();
export const changeEndTimeIntervalInForm = createEvent<string>();
export const changeTimeInForm = createEvent<string>();
export const changeHourInForm = createEvent<number>();

export const resetCityInFormToDefault = createEvent();

$cityInForm.reset(resetCityInFormToDefault);

sample({
  clock: changeHourInForm,
  target: $stepHour,
});

sample({
  clock: changeCityNameInForm,
  target: $cityInForm,
});

sample({
  clock: changeTimeInForm,
  target: $timeInForm,
});

sample({
  clock: changeDateInForm,
  target: $dateInForm,
});

sample({
  clock: changeStartTimeIntervalInForm,
  target: $startTimeIntervalInForm,
});

sample({
  clock: changeEndTimeIntervalInForm,
  target: $endTimeIntervalInForm,
});

sample({
  clock: $startTimeIntervalInForm,
  fn: (startTime) => Number(startTime) + Number($endTimeIntervalInForm).toString(),
  target: $endTimeIntervalInForm,
});
