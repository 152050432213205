import Link from 'next/link';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Button } from '@/shared/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/shared/components/ui/form';
import { Input } from '@/shared/components/ui/input';

export const SendContactsForm = () => {
  const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

  const formSchema = z.object({
    name: z.string(),
    phone: z.string().regex(phoneRegex, 'Некорректный номер телефона'),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      phone: '',
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {};
  return (
    <div className="flex h-full flex-col">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col lg:flex-row lg:space-x-[30px]">
          <div className="mx-auto flex w-full flex-col lg:mx-0 lg:w-[300px] lg:space-y-[10px]">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="mt-4 ">
                  <FormMessage className="text-description" />
                  <FormControl>
                    <Input
                      placeholder="Ваше Имя"
                      type="text"
                      autoComplete="name"
                      {...field}
                      className="w-full bg-white text-black"
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="mt-4">
                  <FormMessage className="h-[18px] text-description"> </FormMessage>

                  <FormControl>
                    <Input
                      type="tel"
                      autoComplete="tel"
                      placeholder="Номер телефона"
                      {...field}
                      className="w-full bg-white text-black"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          <div className="mx-auto mt-[30px] flex w-full flex-col lg:mt-4 lg:w-[300px] lg:flex-col lg:space-y-[28px]">
            <Button
              type="submit"
              className="w-full bg-[#00B190] px-[100px] py-[14px] text-text shadow-none  hover:bg-[#60B0A1]"
            >
              Отправить
            </Button>
            <p className="mt-[4px] w-full text-center text-description md:text-start">
              Нажимая кнопку «Отправить» я соглашаюсь с{' '}
              <Link href="/" className="underline underline-offset-1">
                условиями обработки персональных данных.
              </Link>
            </p>
          </div>
        </form>
      </Form>
    </div>
  );
};
