import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { GetServerSideProps } from 'next/types';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale/ru';
import { allSettled, fork, serialize } from 'effector';
import { useUnit } from 'effector-react';

import { MainLayout } from '@/layouts/main-layout';

import { SendContactsForm } from '@/features/send-contacts-form';

import { citiesQuery } from '@/entities/cities/model';
import {
  $cityInForm,
  $dateInForm,
  $stepHour,
  $timeInForm,
  changeCityNameInForm,
  changeDateInForm,
  changeHourInForm,
  changeTimeInForm,
  resetCityInFormToDefault,
} from '@/entities/search-form/model';

import { CityIndex200CitiesItem } from '@/shared/api/generated/api.generated';
import { Button } from '@/shared/components/ui/button';
import { Calendar } from '@/shared/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/components/ui/popover';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shared/components/ui/select';
import { pagesPath } from '@/shared/config/$path';
import { cn } from '@/shared/lib/cn';
import { EFFECTOR_STATE_KEY } from '@/shared/lib/effector';
import { notified } from '@/shared/lib/effector/notification';

import { indexPage } from './index.model';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const scope = fork();

  await allSettled(indexPage.open, { scope, params: {} });

  return {
    props: {
      [EFFECTOR_STATE_KEY]: serialize(scope),
    },
  };
};

const HomePage = () => {
  const [date, setDate] = React.useState<Date>();
  const [citiesData, cityInForm, changeCity, dateInForm, changeDate, stepHour, changeHour] = useUnit([
    citiesQuery.$data,
    $cityInForm,
    changeCityNameInForm,
    $dateInForm,
    changeDateInForm,
    $stepHour,
    changeHourInForm,
  ]);

  const cities = citiesData?.cities;

  const createTimingWindows = (test: string) => {
    let timeNow = new Date().getHours();

    if (new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') !== test) {
      timeNow = 0;
    }
    const windows = [];

    for (let index = timeNow; index < 24; index++) {
      windows.push(`${index + 1}:00`);
    }
    windows.splice(-1, 1, '23:59');

    if (windows[0] === '1:00') {
      windows.unshift('0:00');
    }
    return windows;
  };

  const constTimingWindowsArray = createTimingWindows(dateInForm);

  const calculatedSelectHeight = constTimingWindowsArray.length > 10 ? 200 : constTimingWindowsArray.length * 40;

  return (
    <MainLayout>
      <div className="overflow-x-hidden">
        <div className="container relative">
          <div className="absolute hidden h-full w-full lg:block">
            <Image alt="теннисные корты" fill src="/images/mainPage-bg-img.png" className="object-contain" />
            <div className="relative h-full  w-full bg-main-page-gradient"></div>
          </div>

          <div className="absolute block h-full w-full lg:hidden">
            <Image alt="теннисные корты" fill src="/images/mainPage-bg-img.png" className="object-cover" />
            <div className="relative h-full w-full bg-main-page-gradient "></div>
          </div>

          <div className="relative pb-[234px] pt-[198px]">
            <span className="block text-title font-black uppercase text-white">Выбирай, бронируй и играй</span>
            <span className="text-text text-white">Найди лучшее место для занятий твоим любимым видом спорта.</span>
            <div className="mt-[40px] flex flex-col justify-between space-y-[20px] rounded-[8px] bg-white px-[20px] py-[14px] font-medium text-black lg:space-y-0 xl:flex-row xl:py-[48px] xl:pr-[12px]">
              <div className="flex flex-col justify-between space-x-[10px] space-y-[20px] lg:flex-row lg:space-y-0">
                <div className="flex flex-col items-center space-y-[20px] lg:flex-row lg:space-x-[10px] lg:space-y-0">
                  <Select
                    onValueChange={(value) => {
                      changeCity(JSON.parse(value) as CityIndex200CitiesItem);
                    }}
                    defaultValue={cities && JSON.stringify(cities[0])}
                  >
                    <SelectTrigger variant="black" className="w-[300px] border-none text-button shadow-none">
                      <div className="relative h-[26px] w-full max-w-[26px]">
                        <Image alt="Иконка геолокации" src="/images/geo-icon.svg" fill />
                      </div>
                      <SelectValue />
                    </SelectTrigger>

                    <SelectContent>
                      {cities?.map((city) => (
                        <SelectItem key={city.id} value={JSON.stringify(city)}>
                          {city.title}
                        </SelectItem>
                      )) ?? ''}
                    </SelectContent>
                  </Select>

                  <Popover>
                    <PopoverTrigger asChild className="text-button">
                      <Button
                        variant={'ghost'}
                        className={cn('w-[134px]  border-none text-left text-black', !date && 'text-muted-foreground')}
                      >
                        <div className="relative mr-[6px] h-full max-h-[26px] w-full max-w-[26px]">
                          <Image alt="Логотип" src="/images/calendar-icon.svg" fill />
                        </div>
                        {date ? (
                          format(date, 'dd.MM.yy')
                        ) : (
                          <span className=" font-medium text-black">{format(new Date(), 'dd.MM.yy')}</span>
                        )}
                      </Button>
                    </PopoverTrigger>

                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={date}
                        locale={ru}
                        onSelect={setDate}
                        initialFocus
                        className="text-black"
                        onDayClick={(value) =>
                          changeDate(value.toLocaleDateString('pt-br').split('/').reverse().join('-'))
                        }
                        disabled={(date) => date < new Date()}
                      />
                    </PopoverContent>
                  </Popover>
                </div>

                <div className="flex flex-col items-center space-y-[20px] lg:flex-row lg:space-x-[10px] lg:space-y-0">
                  <Select
                    defaultValue="1"
                    onValueChange={(value) => {
                      changeHour(Number(value));
                    }}
                  >
                    <SelectTrigger variant="black" className="w-[180px] border-none text-button shadow-none">
                      <div className="relative h-[26px] w-full max-w-[26px]">
                        <Image alt="Иконка геолокации" src="/images/sandglass-icon.svg" fill />
                      </div>
                      <SelectValue className="" />
                    </SelectTrigger>

                    <SelectContent className="">
                      <SelectItem value={'1'}>60 минут</SelectItem>
                      <SelectItem value={'2'}>2 часа</SelectItem>
                      <SelectItem value={'3'}>3 часа</SelectItem>
                      <SelectItem value={'4'}>4 часа</SelectItem>
                    </SelectContent>
                  </Select>

                  <Select
                    onValueChange={(value) => {
                      changeTimeInForm(value);
                    }}
                  >
                    <SelectTrigger variant="black" className="w-[180px] border-none text-button shadow-none">
                      <div className="relative h-[26px] w-full max-w-[26px]">
                        <Image alt="Иконка геолокации" src="/images/time-clock-icon.svg" fill />
                      </div>

                      <SelectValue
                        placeholder={
                          `${constTimingWindowsArray[0]}` === '24:00' ? '23:59' : `${constTimingWindowsArray[0]}`
                        }
                        className=""
                      />
                    </SelectTrigger>

                    <SelectContent
                      style={{
                        height: calculatedSelectHeight,
                      }}
                      className={'w-48 rounded-md'}
                    >
                      {constTimingWindowsArray.map((time) => {
                        return (
                          <SelectItem key={time} value={time}>
                            {time}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="flex pt-0 lg:pt-[10px] xl:pt-0">
                <Button
                  asChild
                  className="mx-auto w-full rounded-[20px] px-[60px] py-[10px] shadow-[0_4px_4px_0_rgba(0,68,55,1)] hover:shadow-[0_4px_4px_0_rgba(0,68,55,0.7)] lg:w-[300px] xl:mr-[28px] xl:w-full"
                >
                  {cityInForm?.title && (
                    <Link href={pagesPath.search._city(cityInForm.title).$url({ query: { date: dateInForm } })}>
                      Поиск
                    </Link>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="container relative flex flex-col pt-[70px]">
            <div className="absolute right-0 hidden lg:block">
              <Image
                alt="Теннисный мяч на заднем фоне"
                src="/images/for-partners-section3-img.png"
                width={494}
                height={100}
              />
            </div>

            <div className="absolute bottom-[160px] right-[-100px] block lg:hidden">
              <Image
                alt="Теннисный мяч на заднем фоне"
                src="/images/for-partners-section3-img.png"
                width={294}
                height={100}
              />
            </div>

            <div className="z-10">
              <h1 className="mb-[40px] text-center text-[24px] font-extrabold uppercase text-[#0044376B] md:text-[80px] lg:mb-0 lg:text-start">
                Booking Court
              </h1>

              <div className="flex max-w-[730px] flex-col space-y-[15px] text-mobileText lg:space-y-[40px] lg:text-text">
                <p>
                  Предоставляет пользователям удобную платформу для поиска, выбора и онлайн-бронирования доступных
                  теннисных кортов по всей России.
                </p>
                <p>
                  Агрегатор придуман для людей, которых объединяет любовь к игре в теннис. Booking Court поможет
                  пользователю найти не только корт для игры в теннис, но и оппонента среди авторизованных
                  пользователей.
                </p>
                <p className="">
                  С каждым днем к нам присоединяются все больше площадок. Вы так же можете помочь нам в развитии нашей
                  платформы.
                </p>
              </div>
            </div>

            <div className="z-10 flex">
              <Button className="mx-auto mb-[125px] mt-[30px] rounded-[20px] px-[10px] py-[20px] text-button font-medium lg:mx-0 lg:ml-[240px]">
                Присоединиться
              </Button>
            </div>
          </div>
        </div>

        <div className="container relative flex flex-col text-white lg:grid lg:grid-cols-3">
          <div className="relative z-10 pb-[280px] pt-[212px] lg:col-span-2 lg:pb-[212px]">
            <span className="block max-w-[700px] text-title font-black">Нужна помощь или остались вопросы?</span>

            <span className="mt-[10px] block text-text">Оставьте свои контакты и наш менеджер свяжется с вами</span>

            <SendContactsForm />
          </div>

          <div className="absolute bottom-0 right-0 hidden h-full w-full max-w-[600px] lg:block">
            <Image
              alt="теннисные корты"
              fill
              src="/images/mainPage-contactSection-bg-img.png"
              className="object-contain"
            />
            <div className="relative h-full w-full bg-main-page-contacts-gradient"></div>
          </div>

          <div className="absolute bottom-0 block h-full max-h-[460px] w-full lg:hidden">
            <Image
              alt="теннисные корты"
              fill
              src="/images/mainPage-contactSection-bg-mobile-img.png"
              className="object-cover"
            />
            <div className="relative h-full w-full  bg-main-page-contacts-gradient"></div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default HomePage;
