import { createQuery } from '@farfetched/core';
import { createEffect } from 'effector';

import { cityIndex } from '@/shared/api/generated/api.generated';

export const citiesQuery = createQuery({
  effect: createEffect(() => {
    return cityIndex();
  }),
});
